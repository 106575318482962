import React, { useState, useEffect } from 'react'
import "twin.macro"
import { ErrorPage } from '../components/errorPage'

const UnavailablePage = () => <ErrorPage 
	title="File Unavailable" 
	details={<p>The file you requested is unavailable.</p>}
/>

export default UnavailablePage
